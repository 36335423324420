.btn.btn-link {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align to the left */
    padding-left: 0; /* Remove padding if needed */
    text-decoration: none; /* Remove underline */
    background: none; /* Remove any background */
    border: none; /* Remove borders */
    cursor: pointer; /* Change mouse hover to indicate clickable */
  }
  
  .btn.btn-link .fa-arrow-left {
    margin-right: 0.5em; /* Adjust space between icon and text */
  }
  
  /* Additional styles to ensure button doesn't look like a link */
  .btn.btn-link:hover,
  .btn.btn-link:active,
  .btn.btn-link:focus {
    text-decoration: none; /* Removes underline on hover, focus */
    outline: none; /* Removes outline */
  }
  


@media (min-width: 768px) {
  .tag-button {
    padding: .75rem 1.5rem;
  }
}
.tags-container {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
  gap: 10px; /* Add some space between buttons */
}

.tag-button {
  /* existing styles */
  width: auto; /* Override full width to auto */
  margin-right: 10px; /* Add some space between buttons */
}

/* Add this to remove the scrollbar aesthetic but retain functionality */
.tags-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.tags-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Custom CSS file */
.tags-scroll-container {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  padding-bottom: 15px; /* Increase bottom padding to ensure visibility of the last item */
}


/* Adjustments for small screens */
@media (max-width: 576px) {
  .tags-scroll-container {
    max-height: 150px; /* Example max-height, adjust based on your needs */
  }
}

.tag-button {
  background-color: #FFFFFF; /* White background */
  border: 1px solid #D1D5DB; /* Light grey border */
  border-radius: 20px; /* Rounded corners */
  color: #111827; /* Dark text color */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; /* Center content */
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem; /* Adjust font-size as needed */
  font-weight: 600; /* Semi-bold text */
  padding: 8px 16px; /* Top and bottom padding, left and right padding */
  text-decoration: none;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s; /* Smooth transition for hover effects */
  margin: 0 4px; /* Spacing between buttons */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

.tag-button.selected {
  background-color: #111827; /* Dark background for selected state */
  color: #FFFFFF; /* White text for selected state */
}

.tag-button:not(.selected):hover {
  background-color: #F3F4F6; /* Light grey background on hover for non-selected buttons */
  border-color: #D1D5DB; /* Adjust border color on hover if needed */
}

/* Assuming there's an icon with a class like 'tag-icon', you might add some styling for it */
.tag-icon {
  margin-right: 8px; /* Space between icon and text */
}

.tags-scroll-container {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  padding: 5px; /* Add padding inside the container */

  /* Maximum width for smaller screens */
}
.filter-by{
  margin: 0px;
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
 
  .tags-scroll-container {
    
    max-width: 300px; /* Adjust as needed */
  }
}

/* Hide scrollbar for aesthetic reasons, but retain functionality */


