.marketplaceimg-container {
    height: 500px; /* Set the desired height */
    overflow: hidden; /* This will crop the image */
    display: inline-block; /* Adjust as needed */
    position: relative;
}

.marketplaceimg {
    width: auto; /* Maintain the aspect ratio */
    height: 100%; /* Make the image fill the container height */
    position: absolute;
    top: 50%; /* Center the image vertically */
    left: 50%; /* Center the image horizontally */
    transform: translate(-50%, -50%); /* Adjust the position to center */
}
.mid {
    display: flex;
    flex-direction: row;
    align-items: center; /* Align items vertically in the center */
    justify-content: center; /* Center the content horizontally */
    text-align: left; /* Align the text to the left */
  }
  
  .mid > div {
    flex: 1; /* Give each child div an equal amount of space */
  }
  
  .mid img {
    max-width: 100%; /* Ensures the image is responsive and doesn't overflow its container */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Ensures the image doesn't have extra space below it */
  }
  .deniedText{
    font-size: 2rem;
  }

.caption{
    margin-top: -50px;
}
.location-denied{
    text-align: center;
    color:rgb(0, 0, 0)
}
.center{
    text-align: center;
}
.center_grid {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-bar-container {
  position: relative;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;

}

.search-bar-container input[type="text"] {
  width: 100%;
  padding: 10px;
  padding-right: 40px; /* Make room for the icon */
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  
}

.search-icon {
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  z-index: 10; /* Ensure the icon is above other elements */
}
.picture_cred{
  text-align: end;
}
.error-container{
  margin-left: 10px;
  margin-right: 10px;
}

.error-title{
  font-size: 50px;
}
.error-text{
  font-size: 30px;
}

/* .loc_img{
  width: 50rem;
} */
@media (max-width: 768px) { /* Adjust this breakpoint as needed */
    .marketplaceimg {
        width: 100%; /* Fill the container width */
        height: auto; /* Adjust height automatically to maintain aspect ratio */
        top: 0;
        left: 0;
        transform: none;
    }
    .marketplaceimg-container {
        height: fit-content; /* Set the desired height */
        overflow: hidden; /* This will crop the image */
        display: inline-block; /* Adjust as needed */
        position: relative;
    }
    .mid {
        flex-direction: column; /* Stack items vertically on small screens */
      }
    
      .mid > div {
        width: 100%; /* Each child takes the full width on small screens */
      }
    
      .mid p {
        margin-bottom: 1em; /* Provide some space between the text and the image */
      }
    
}
