/* OrderStatus.css */

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
  
  .cardWrapper {
    width: 25rem; /* Updated width of the card */
  }
  
  .cardBodyWrapper {
    /* You can add styles for card-body here if needed */
  }
  /* OrderStatus.css */

/* ... existing styles ... */

.full-width-button {
    display: block; /* Makes the button a block element */
    width: 100%; /* Sets the button width to fill the container */
    margin-left: 0px;
}
.tyo{
  text-align: center;
}
  
  