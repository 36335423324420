/* productList.css */

.centered-product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally within the container */
}

.product-item {
  margin: 5px; /* This adds a margin of 5px to all sides of each card */
  /* Adjustments for larger screens can remain as is */
}

/* Responsive adjustments for mobile screens */
@media (max-width: 480px) {
  .product-item {
    flex: 0 0 48%; /* Sets the basis for each card to roughly half the container width */
    max-width: 48%; /* Prevents the card from exceeding half the container width */
    /* Adjust margin if needed to ensure there's space between cards */
  }

  .centered-product-list {
    justify-content: space-around; /* This will help ensure spacing around items is consistent */
  }
}
