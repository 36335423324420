/* OrderSuccess.css */

/* Style for larger screens (default) */
.img-order-success {
    max-width: 500px;
    height: auto;
    margin: 20px 0;
}

/* Style for smaller screens */
@media (max-width: 600px) {
    .img-order-success {
        width: 200px; /* Smaller width for smaller screens */
        /* Height is auto to maintain aspect ratio */
    }
}
