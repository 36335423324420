
.App {
  text-align: center;
}
.app{
  margin: 0%;
  padding: 0%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* styles.css (or your preferred CSS file) */
.footer-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  background-color: #000000; /* Add your preferred background color */
  color: #fff; /* Add your preferred text color */
}
.cookie-consent-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Ensure it's above other elements */
}

.cookie-consent-box {
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  text-align: center;
}

.cookie-consent-box p {
  margin: 0;
  margin-bottom: 10px;
}

.cookie-consent-box button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}
.floating-checkout-btn {
  position: fixed;
  bottom: 30px;
  right: 5%;
  z-index: 1000;
  padding: 5px; /* Adjusted for a rectangular shape */
  border-radius: 15px; /* Less rounded corners for a rectangular shape */
  background-color: #000000; /* Bootstrap primary color */
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; /* Adjust font size as needed */
}




.maintenance-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.maintenance-content {
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.maintenance-content h1 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.maintenance-content p {
  font-size: 1em;
  color: #666;
}
