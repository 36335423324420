.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This will push the button and select to the bottom */
  }
  
  .card-body .d-flex {
    width: 100%; /* Ensure the container takes up full width */
  }
  
  .w-50 {
    width: 50%; /* Each child takes up half the width */
  }
  
  @media (min-width: 768px) { /* Adjust breakpoint as needed */
 
    .bigwidth{
      width: 18rem;
    }
  }
  .user-product-item {
    display: flex;
    flex-direction: column;
  }
  
  @media (max-width: 768px) { /* Adjust for smaller screens */
    .product-item-content {
      display: flex;
      flex-direction: row;
    }
  
    
    .product-image {
      flex: 1; /* Adjust the flex value based on desired size */
      display: flex;
      align-items: center; /* This centers the content vertically */
      justify-content: center; /* This centers the content horizontally */
      /* Ensure the container has a specified height or it will shrink-wrap to the image size. */
      height: 100%; /* You might adjust this value based on your layout needs */
    }
    
  
    .card-body {
      flex: 2; /* Adjust accordingly */
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
   
  }
  